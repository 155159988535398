.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap; /* Enable wrapping */
}

.filtersGroup {
    display: flex;
    gap: 20px;
    align-items: center;
    flex-wrap: wrap; /* Enable wrapping */
}

.filerItem {
    display: flex;
    gap: 10px;
    align-items: center;
    min-width: 150px; /* Minimum width */
    flex-shrink: 0; /* Prevent shrinking */
}
