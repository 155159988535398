@media print {
    .simpleInput {
        &:global(.ant-input-disabled) {
            color: #000;
        }
        &:global(.ant-picker-disabled input) {
            color: #000 !important;
        }
    }
}
