.printTable {
    width: 100%;
}

.header {
    display: flex;
    justify-content: space-between;
    align-content: baseline;
    margin: 10px 0;
    width: 100%;
    padding: 40px;
}

.mainTable {
    & td,
    th {
        border-color: #aaa !important;
        border-inline-end: 1px solid #888888 !important;
    }
    & :global(.ant-table-container) {
        border-color: #aaa !important;
    }
}
