@import '../../../styles/colors.scss';

.actionsBlock {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
}

.actionButtons {
    display: flex;
    gap: 10px;
}

.table {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 48px;

    & :global(.ant-table-thead .ant-table-cell) {
        height: 46px;
        padding-left: 17px !important;
    }
    & :global(.ant-table-thead .ant-table-cell) {
        &.aliquots {
            padding: 2px !important;
            text-align: center;
        }
    }
    & :global(.ant-table-cell) {
        padding: 0 16px !important;
    }
    & :global(.ant-table-pagination) {
        margin-top: 24px !important;
    }
}

td {
    &.aliquots {
        &:global(.ant-table-cell) {
            padding: 0 !important;
        }
        & :global(.ant-select) {
            width: 100%;
            height: 100%;
        }
        & :global(.ant-select-selector) {
            height: 40px !important;
        }
    }
    &.flagCell {
        &:global(.ant-table-cell) {
            padding: 0 !important;
        }
    }
}
.specimenColumnWrapper {
    min-width: fit-content;
    overflow: hidden;
}

.mrnColumnWrapper {
    min-width: fit-content;
    overflow: hidden;
}

.nameColumnWrapper,
.collectedColumnWrapper {
    max-width: 200px;
    overflow: hidden;
    white-space: nowrap;
}

.nameColumn,
.locationColumn,
.specimenColumn {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}

.printNote {
    max-width: 200px;
    white-space: nowrap;
    font-size: 10px;
}
.printFlags {
    width: 100px;
}

@media print {
    @page {
        size: landscape;
    }
    .nameColumnWrapper,
    .locationColumnWrapper,
    .specimenColumnWrapper {
        min-width: fit-content;
    }
}

.searchInput {
    width: auto;
}

.errored {
    background-color: $dust-red;
}
