.identCell {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    cursor: pointer;
    min-width: fit-content;
}

.copiedIconWrapper {
    display: flex;
    align-items: center;
    font-size: 10px;
    justify-content: center;
    width: 10px;
}
