@import '../../../styles/colors.scss';

.tagActive {
    cursor: pointer;
}

.tag {
    cursor: default;
    display: flex;
    align-items: center;
    gap: 4px;
    margin: 2px;
}

.flagSelect {
    width: 100%;
    min-width: 180px;
    // position: relative;

    & :global(.react-select__control) {
        padding-left: 16px;
        border-color: transparent;
        background-color: transparent;
        box-shadow: none;
        &:hover {
            border-color: transparent;
            cursor: pointer;
            & :global(.react-select__clear-indicator) {
                display: flex;
            }
        }
    }
    & :global(.react-select__value-container) {
        display: flex;
        padding: 0;
    }
    & :global(.react-select__clear-indicator) {
        display: none;
    }
}

.selectLoading {
    opacity: 0.3;
    &:before {
        content: '';
        box-sizing: border-box;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 20px;
        height: 20px;
        margin-top: -10px;
        margin-left: -10px;
        border-radius: 50%;
        border: 2px solid #323232;
        border-top-color: transparent;
        animation: spinner 0.6s linear infinite;
        z-index: 10;
    }
}

@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}

.removeBtn {
    display: flex;
    font-size: 16px;
    transition: 0.2s;
    cursor: pointer;
    &:hover {
        opacity: 0.8;
        transform: scale(1.3);
    }
}
