@import '../../styles/colors.scss';

.identColumnHeader {
    display: flex;
    align-items: center;
    gap: 8px;
}

.icon {
    font-size: 10px;
    color: $blue;
}
